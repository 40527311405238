import React, { createContext, useContext, useState } from 'react';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.js';
import { useAuth } from './AuthContext.js';

const OrderContext = createContext();

export const useOrder = () => {
    const context = useContext(OrderContext);
    if (!context) {
        throw new Error('useOrder must be used within an OrderProvider');
    }
    return context;
};

export const OrderProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);

    const createOrder = async (orderData) => {
        setLoading(true);
        const userId = currentUser ? currentUser.uid : null;
        const guestUserId = !currentUser ? `guest-${Date.now()}` : null;

        try {
            const orderRef = doc(collection(db, 'orders'));
            await setDoc(orderRef, {
                ...orderData,
                userId: userId,
                guestUserId: guestUserId,
                orderDate: new Date().toISOString(),
                status: 'pending',
                orderNumber: `ORD-${Date.now()}`
            });

            // Store guestUserId in local storage
            if (guestUserId) {
                localStorage.setItem('guestUserId', guestUserId);
            }

            return orderRef.id;
        } catch (error) {
            console.error("Error creating order:", error);
            throw new Error('Failed to create order');
        } finally {
            setLoading(false);
        }
    };

    const getOrder = async (orderId) => {
        try {
            const orderRef = doc(db, 'orders', orderId);
            const orderDoc = await getDoc(orderRef);
            
            if (orderDoc.exists()) {
                const orderData = orderDoc.data();
                
                // Check authorization for both authenticated and guest users
                const isAuthorized = 
                    (currentUser && orderData.userId === currentUser.uid) ||
                    (!currentUser && orderData.guestUserId);
                
                if (isAuthorized) {
                    return {
                        id: orderDoc.id,
                        ...orderData
                    };
                } else {
                    throw new Error('Not authorized to view this order');
                }
            }
            return null;
        } catch (error) {
            console.error('Error fetching order:', error);
            throw error;
        }
    };

    const value = {
        createOrder,
        getOrder,
        loading
    };

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    );
};