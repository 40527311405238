import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, updateDoc, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrash, FaSpinner, FaStar, FaSearch, FaSort } from 'react-icons/fa';

const ProductsList = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [sortBy, setSortBy] = useState('name');
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 12;

    // Predefined size options
    const sizesMap = {
        clothing: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
        shoes: ['US 6', 'US 7', 'US 8', 'US 9', 'US 10', 'US 11', 'US 12']
    };

    // Categories array
    const categories = [
        { id: 'clothing', name: 'Clothing' },
        { id: 'shoes', name: 'Shoes' },
        { id: 'fresh-food', name: 'Fresh Food' },
        { id: 'groceries', name: 'Groceries' }
    ];

    useEffect(() => {
        fetchProducts();
    }, [sortBy]);

    const fetchProducts = async () => {
        try {
            const productsRef = collection(db, 'products');
            const q = query(productsRef, orderBy(sortBy));
            const querySnapshot = await getDocs(q);
            const productsData = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    createdAt: data.createdAt ? (data.createdAt.toDate ? data.createdAt.toDate() : new Date(data.createdAt)) : new Date(),
                    // Initialize sizes if not present
                    sizes: data.sizes || (
                        ['clothing', 'shoes'].includes(data.category) 
                        ? sizesMap[data.category] 
                        : []
                    )
                };
            });
            setProducts(productsData);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    };

    // Filter and sort functions
    const filteredProducts = products.filter(product => {
        const matchesSearch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = !selectedCategory || product.category === selectedCategory;
        return matchesSearch && matchesCategory;
    });

    // Pagination
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    // Handlers
    const handleDelete = async (productId) => {
        if (window.confirm('Are you sure you want to delete this product?')) {
            try {
                await deleteDoc(doc(db, 'products', productId));
                setProducts(products.filter(product => product.id !== productId));
            } catch (error) {
                console.error('Error deleting product:', error);
            }
        }
    };

    const handleFeatureToggle = async (product) => {
        try {
            const productRef = doc(db, 'products', product.id);
            await updateDoc(productRef, {
                isFeatured: !product.isFeatured
            });
            setProducts(products.map(p => 
                p.id === product.id 
                    ? { ...p, isFeatured: !p.isFeatured }
                    : p
            ));
        } catch (error) {
            console.error('Error updating featured status:', error);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <FaSpinner className="w-8 h-8 animate-spin text-gray-500" />
            </div>
        );
    }

    return (
        <div className="space-y-4">
            {/* Header and Controls */}
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold">Products</h2>
                <Link
                    to="/dashboard/add-product"
                    className="bg-[#FEEA00] hover:bg-[#E6D300] text-black px-4 py-2 rounded-lg"
                >
                    Add Product
                </Link>
            </div>

            {/* Filters and Search */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 bg-white p-4 rounded-lg shadow">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search products..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full pl-10 pr-4 py-2 border rounded-lg"
                    />
                    <FaSearch className="absolute left-3 top-3 text-gray-400" />
                </div>

                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="border rounded-lg p-2"
                >
                    <option value="">All Categories</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>

                <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="border rounded-lg p-2"
                >
                    <option value="name">Sort by Name</option>
                    <option value="createdAt">Sort by Date</option>
                    <option value="price">Sort by Price</option>
                </select>
            </div>

 {/* Products Grid */}
 <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {currentProducts.map((product) => (
                    <div key={product.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
                        <div className="aspect-square w-full bg-gray-50">
                            <img
                                src={product.mainImage || (product.images && product.images[0]) || '/placeholder-product.png'}
                                alt={product.name}
                                className="w-full h-full object-contain"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/placeholder-product.png';
                                }}
                            />
                        </div>
                        
                        <div className="p-2">
                            <h3 className="font-medium text-sm truncate">
                                {product.name}
                            </h3>
                            
                            <div className="text-xs text-gray-600 mt-1">
                                <p>R{product.price?.toFixed(2)}</p>
                                <p>Stock: {product.stock}</p>
                            </div>

                            {/* Size Display for Clothing and Shoes */}
                            {(['clothing', 'shoes'].includes(product.category)) && (
                                <div className="mt-2">
                                    <p className="text-xs font-semibold mb-1">Available Sizes:</p>
                                    <div className="flex flex-wrap gap-1">
                                        {product.sizes?.map(size => (
                                            <span 
                                                key={size} 
                                                className="text-xs bg-gray-100 px-2 py-0.5 rounded"
                                            >
                                                {size}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}

                            <div className="flex justify-between items-center mt-2 gap-1">
                            <Link
                                    to={`/dashboard/edit-product/${product.id}`}
                                    className="flex-1 text-center text-xs px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Edit
                                </Link>
                                <button
                                    onClick={() => handleFeatureToggle(product)}
                                    className={`flex-1 text-center text-xs px-2 py-1 rounded ${
                                        product.isFeatured 
                                        ? 'bg-yellow-400 text-white' 
                                        : 'bg-gray-200 text-gray-700'
                                    }`}
                                >
                                    {product.isFeatured ? 'Featured' : 'Feature'}
                                </button>
                                <button
                                    onClick={() => handleDelete(product.id)}
                                    className="flex-1 text-center text-xs px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-center space-x-2 mt-4">
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentPage(index + 1)}
                        className={`px-3 py-1 rounded ${
                            currentPage === index + 1
                                ? 'bg-[#FEEA00] text-black'
                                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

            {filteredProducts.length === 0 && !loading && (
                <div className="text-center py-12 text-gray-500">
                    No products found
                </div>
            )}
        </div>
    );
};

export default ProductsList; 