import { createContext, useContext } from 'react';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase.js';

const NewsletterContext = createContext();

export const useNewsletter = () => {
    return useContext(NewsletterContext);
};

export const NewsletterProvider = ({ children }) => {
    const subscribe = async (email) => {
        try {
            // Check if email already exists
            const q = query(
                collection(db, 'newsletter_subscribers'),
                where('email', '==', email)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                throw new Error('Email already subscribed');
            }

            // Add new subscriber
            await addDoc(collection(db, 'newsletter_subscribers'), {
                email,
                subscribeDate: new Date().toISOString(),
                active: true
            });

            return { success: true, message: 'Successfully subscribed!' };
        } catch (error) {
            console.error('Newsletter subscription error:', error);
            throw error;
        }
    };

    const value = {
        subscribe
    };

    return (
        <NewsletterContext.Provider value={value}>
            {children}
        </NewsletterContext.Provider>
    );
}; 