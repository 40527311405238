import React from 'react';

const OrderDetails = ({ order, onClose }) => {
    if (!order) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-full max-w-3xl shadow-lg rounded-md bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-bold text-gray-900">Order Details</h3>
                    <button 
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-500"
                    >
                        <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                        <h4 className="font-semibold text-gray-800 mb-2">Order Information</h4>
                        <p className="text-sm">Order Number: {order.orderNumber}</p>
                        <p className="text-sm">Date: {order.createdAt?.toDate().toLocaleDateString()}</p>
                        <p className="text-sm">Status: 
                            <span className={`ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                                ${order.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                                  order.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' : 
                                  'bg-gray-100 text-gray-800'}`}>
                                {order.status}
                            </span>
                        </p>
                    </div>

                    <div>
                        <h4 className="font-semibold text-gray-800 mb-2">Customer Information</h4>
                        <p className="text-sm">Name: {order.customerName}</p>
                        <p className="text-sm">Email: {order.email}</p>
                        <p className="text-sm">Phone: {order.phone}</p>
                    </div>
                </div>

                <div className="mb-6">
                    <h4 className="font-semibold text-gray-800 mb-2">Shipping Address</h4>
                    <p className="text-sm">{order.shippingAddress?.street}</p>
                    <p className="text-sm">
                        {order.shippingAddress?.city}, {order.shippingAddress?.province} {order.shippingAddress?.postalCode}
                    </p>
                    <p className="text-sm">{order.shippingAddress?.country}</p>
                </div>

                <div className="mb-6">
                    <h4 className="font-semibold text-gray-800 mb-2">Order Items</h4>
                    <div className="bg-gray-50 rounded-lg p-4">
                        {order.items?.map((item, index) => (
                            <div key={index} className="flex justify-between items-center py-2 border-b last:border-0">
                                <div>
                                    <p className="text-sm font-medium">{item.name}</p>
                                    <p className="text-xs text-gray-500">Quantity: {item.quantity}</p>
                                </div>
                                <p className="text-sm">R {(item.price * item.quantity).toFixed(2)}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="border-t pt-4">
                    <div className="flex justify-between text-sm mb-1">
                        <span>Subtotal</span>
                        <span>R {order.subtotal?.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between text-sm mb-1">
                        <span>Tax</span>
                        <span>R {order.tax?.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between text-sm mb-2">
                        <span>Shipping</span>
                        <span>R {order.shippingCost?.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between font-semibold text-lg">
                        <span>Total</span>
                        <span>R {order.total?.toFixed(2)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetails;