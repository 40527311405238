import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase.js';
import ProductCard from '../../components/products/ProductCard.js';
import SearchBar from '../../components/search/SearchBar.js';
import { useSearchParams } from 'react-router-dom';
import ProductModal from '../../components/products/ProductModal';
import { FaShoppingCart } from 'react-icons/fa';
import { useCart } from '../../contexts/CartContext.js';

const categories = [
    
    { id: 'clothing', name: 'Clothing' },
    { id: 'groceries', name: 'Groceries' },
    { id: 'shoes', name: 'Shoes' },
    { id: 'spices', name: 'Spices' }
];

const sortOptions = [
    { value: 'price-asc', label: 'Price: Low to High' },
    { value: 'price-desc', label: 'Price: High to Low' },
    { value: 'name-asc', label: 'Name: A to Z' },
    { value: 'name-desc', label: 'Name: Z to A' }
];

const Shop = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [sortBy, setSortBy] = useState('price-asc');
    const [searchParams] = useSearchParams();
    const searchTerm = searchParams.get('search') || '';
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { cartItems } = useCart();

    useEffect(() => {
        
            // Scroll to the top when the component is mounted
            window.scrollTo(0, 0);
        

        const fetchProducts = async () => {
            try {
                setLoading(true);
                let q = collection(db, "products");
                
                
                // Apply category filter
                if (selectedCategory !== 'all') {
                    q = query(q, where("category", "==", selectedCategory));
                }

                const querySnapshot = await getDocs(q);
                let productsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Apply search filter
                if (searchTerm) {
                    productsData = productsData.filter(product => 
                        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        product.description?.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                }

                // Apply sorting
                productsData.sort((a, b) => {
                    switch (sortBy) {
                        case 'price-asc':
                            return a.price - b.price;
                        case 'price-desc':
                            return b.price - a.price;
                        case 'name-asc':
                            return a.name.localeCompare(b.name);
                        case 'name-desc':
                            return b.name.localeCompare(a.name);
                        default:
                            return 0;
                    }
                });

                setProducts(productsData);
            } catch (err) {
                setError("Failed to load products");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [selectedCategory, sortBy, searchTerm]);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    if (loading) return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
    );
    
    if (error) return <div className="text-center text-red-500 mt-8">{error}</div>;

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="mb-8">
                <SearchBar />
            </div>

            {/* Categories */}
            <div className="mb-8">
                <h2 className="text-lg font-semibold mb-4">Categories</h2>
                <div className="flex flex-wrap gap-4">
                    <button
                        onClick={() => setSelectedCategory('all')}
                        className={`px-4 py-2 rounded-full ${
                            selectedCategory === 'all' 
                                ? 'bg-[#FEEA00] text-gray-900' 
                                : 'bg-gray-100 hover:bg-gray-200'
                        }`}
                    >
                        All
                    </button>
                    {categories.map(category => (
                        <button
                            key={category.id}
                            onClick={() => setSelectedCategory(category.id)}
                            className={`px-4 py-2 rounded-full flex items-center gap-2 ${
                                selectedCategory === category.id 
                                    ? 'bg-blue-500 text-white' 
                                    : 'bg-gray-100 hover:bg-gray-200'
                            }`}
                        >
                            <span>{category.icon}</span>
                            <span>{category.name}</span>
                        </button>
                    ))}
                </div>
            </div>

            {/* Sort dropdown */}
            <div className="mb-8 flex justify-end">
                <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                    {sortOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            {/* Products grid */}
            {products.length === 0 ? (
                <div className="text-center text-gray-500 mt-8">
                    No products found
                </div>
            ) : (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 sm:gap-4">
                    {products.map((product) => (
                        <ProductCard 
                            key={product.id} 
                            product={product} 
                            onProductClick={handleProductClick}
                        />
                    ))}
                </div>
            )}

            {/* Add Modal */}
            <ProductModal
                product={selectedProduct}
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedProduct(null);
                }}
            />

            {/* Cart Icon */}
            <Link to="/cart" className="fixed top-20 sm:top-24 right-4 sm:right-6 bg-[#FEEA00] text-gray-900 p-2 sm:p-3 rounded-full shadow-lg hover:bg-[#E6D300] z-50">
                <div className="relative">
                    <FaShoppingCart className="w-4 h-4 sm:w-6 sm:h-6" />
                    {cartItems.length > 0 && (
                        <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-4 h-4 sm:w-5 sm:h-5 flex items-center justify-center">
                            {cartItems.length}
                        </span>
                    )}
                </div>
            </Link>
        </div>
    );
};

export default Shop;