import React, { useState } from 'react';
import { FaTimes, FaHeart, FaRegHeart } from 'react-icons/fa';
import { useCart } from '../../contexts/CartContext';
import { useWishlist } from '../../contexts/WishlistContext';

const ProductModal = ({ product, isOpen, onClose }) => {
    const [selectedImage, setSelectedImage] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const { addToCart } = useCart();
    const { addToWishlist, removeFromWishlist, isInWishlist } = useWishlist();

    if (!isOpen || !product) return null;

    const handleAddToCart = () => {
        addToCart({
            id: product.id,
            name: product.name,
            price: product.price,
            image: product.images?.[0] || product.mainImage,
            quantity
        });
        onClose();
    };

    const handleWishlistToggle = (e) => {
        e.stopPropagation();
        if (isInWishlist(product.id)) {
            removeFromWishlist(product.id);
        } else {
            addToWishlist(product);
        }
    };

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-75">
            <div className="min-h-screen px-4 flex items-center justify-center">
                {/* Modal Container */}
                <div className="relative bg-white w-full max-w-3xl rounded-lg shadow-xl overflow-hidden">
                    {/* Close Button */}
                    <button
                        onClick={onClose}
                        className="absolute right-2 top-2 z-10 p-2 rounded-full bg-white shadow-md hover:bg-gray-50 transition-colors"
                    >
                        <FaTimes className="w-4 h-4 text-gray-500" />
                    </button>

                    {/* Mobile Layout */}
                    <div className="md:hidden">
                        {/* Image Section */}
                        <div className="relative bg-gray-50">
                            <div className="aspect-square">
                                <img
                                    src={product.images?.[selectedImage] || product.mainImage}
                                    alt={product.name}
                                    className="w-full h-full object-contain"
                                />
                            </div>
                            
                            {/* Wishlist Button - Mobile */}
                            <button
                                onClick={handleWishlistToggle}
                                className="absolute left-2 top-2 p-2 rounded-full bg-white shadow-md"
                            >
                                {isInWishlist(product.id) ? (
                                    <FaHeart className="w-4 h-4 text-red-500" />
                                ) : (
                                    <FaRegHeart className="w-4 h-4 text-gray-400" />
                                )}
                            </button>

                            {/* Thumbnails - Mobile */}
                            {product.images && product.images.length > 1 && (
                                <div className="absolute bottom-2 left-0 right-0 flex justify-center gap-1 px-2">
                                    {product.images.map((_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => setSelectedImage(index)}
                                            className={`w-2 h-2 rounded-full transition-all ${
                                                selectedImage === index 
                                                    ? 'bg-[#FEEA00]' 
                                                    : 'bg-gray-300'
                                            }`}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Product Info - Mobile */}
                        <div className="p-4">
                            <h2 className="text-xl font-bold text-gray-900 mb-2">{product.name}</h2>
                            <p className="text-2xl font-bold text-gray-900 mb-3">
                                R {product.price?.toFixed(2)}
                            </p>
                            
                            <div className="mb-3">
                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                    product.stock > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                }`}>
                                    {product.stock > 0 ? 'Available' : 'Out of stock'}
                                </span>
                            </div>

                            <p className="text-sm text-gray-600 mb-4">{product.description}</p>

                            {product.stock > 0 && (
                                <div className="flex gap-2">
                                    <select
                                        value={quantity}
                                        onChange={(e) => setQuantity(Number(e.target.value))}
                                        className="w-20 rounded-md border-gray-300 text-sm"
                                    >
                                        {[...Array(Math.min(10, product.stock))].map((_, i) => (
                                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                                        ))}
                                    </select>
                                    <button
                                        onClick={handleAddToCart}
                                        className="flex-1 bg-[#FEEA00] hover:bg-[#E6D300] text-gray-900 py-2 px-4 rounded-md text-sm font-medium"
                                    >
                                        Add to Cart
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Desktop Layout */}
                    <div className="hidden md:grid md:grid-cols-2">
                        {/* Left Side - Images */}
                        <div className="p-6 bg-gray-50">
                            {/* Main Image */}
                            <div className="aspect-square rounded-lg overflow-hidden bg-white">
                                <img
                                    src={product.images?.[selectedImage] || product.mainImage}
                                    alt={product.name}
                                    className="w-full h-full object-contain"
                                />
                            </div>

                            {/* Thumbnails */}
                            {product.images && product.images.length > 1 && (
                                <div className="flex gap-2 mt-4 overflow-x-auto">
                                    {product.images.map((image, index) => (
                                        <button
                                            key={index}
                                            onClick={() => setSelectedImage(index)}
                                            className={`flex-shrink-0 w-16 h-16 rounded-md overflow-hidden border-2 transition-all
                                                ${selectedImage === index ? 'border-[#FEEA00]' : 'border-transparent hover:border-gray-200'}`}
                                        >
                                            <img
                                                src={image}
                                                alt={`${product.name} view ${index + 1}`}
                                                className="w-full h-full object-cover"
                                            />
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Right Side - Product Info */}
                        <div className="p-6 flex flex-col h-full">
                            <div className="flex-grow">
                                {/* Product Title & Price */}
                                <div className="flex justify-between items-start mb-4">
                                    <div>
                                        <h2 className="text-2xl font-bold text-gray-900 mb-2">
                                            {product.name}
                                        </h2>
                                        <p className="text-3xl font-bold text-gray-900">
                                            R {product.price?.toFixed(2)}
                                        </p>
                                    </div>
                                    <button
                                        onClick={handleWishlistToggle}
                                        className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                                    >
                                        {isInWishlist(product.id) ? (
                                            <FaHeart className="w-6 h-6 text-red-500" />
                                        ) : (
                                            <FaRegHeart className="w-6 h-6 text-gray-400" />
                                        )}
                                    </button>
                                </div>

                                {/* Stock Status */}
                                <div className="mb-4">
                                    <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                                        ${product.stock > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                        {product.stock > 0 ? 'Available' : 'Out of stock'}
                                    </span>
                                </div>

                                {/* Description */}
                                <div className="prose prose-sm text-gray-600 mb-6">
                                    <p>{product.description}</p>
                                </div>
                            </div>

                            {/* Add to Cart Section */}
                            {product.stock > 0 && (
                                <div className="mt-auto pt-4 border-t border-gray-100">
                                    <div className="flex gap-4">
                                        <select
                                            value={quantity}
                                            onChange={(e) => setQuantity(Number(e.target.value))}
                                            className="w-24 rounded-md border-gray-300 shadow-sm focus:border-[#FEEA00] focus:ring-[#FEEA00]"
                                        >
                                            {[...Array(Math.min(10, product.stock))].map((_, i) => (
                                                <option key={i + 1} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                        </select>
                                        <button
                                            onClick={handleAddToCart}
                                            className="flex-1 bg-[#FEEA00] hover:bg-[#E6D300] text-gray-900 py-3 px-6 rounded-md font-medium transition-colors"
                                        >
                                            Add to Cart
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal; 