import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAayOhQxSf6nO8w_ZKuAr4YhvbpXzArW0E",
    authDomain: "africanshop1001.firebaseapp.com",
    projectId: "africanshop1001",
    storageBucket: "africanshop1001.firebasestorage.app",
    messagingSenderId: "301171349644",
    appId: "1:301171349644:web:9388d8fea89158c2ab43a9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  experimentalForceLongPolling: true
});
export const storage = getStorage(app);

export default app;