import React, { useState } from 'react';
import { useWishlist } from '../../contexts/WishlistContext.js';
import { useCart } from '../../contexts/CartContext.js';
import { TrashIcon } from '@heroicons/react/24/outline';
import ProductModal from '../../components/products/ProductModal';

const Wishlist = () => {
    const { wishlistItems, removeFromWishlist } = useWishlist();
    const { addToCart } = useCart();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProduct(null);
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-6">My Wishlist</h1>
            
            {wishlistItems.length === 0 ? (
                <p className="text-gray-500">Your wishlist is empty.</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {wishlistItems.map((item) => (
                        <div key={item.id} className="bg-white rounded-lg shadow-md p-4">
                            <div className="relative">
                                <img
                                    src={item.images[0]}
                                    alt={item.name}
                                    className="w-full h-48 object-cover rounded-md cursor-pointer"
                                    onClick={() => openModal(item)}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/path/to/fallback-image.jpg';
                                    }}
                                />
                                <button
                                    onClick={() => removeFromWishlist(item.id)}
                                    className="absolute top-2 right-2 p-2 rounded-full bg-white shadow-md hover:bg-gray-100"
                                >
                                    <TrashIcon className="h-5 w-5 text-red-500" />
                                </button>
                            </div>
                            
                            <h3 className="mt-4 text-lg font-semibold">{item.name}</h3>
                            <p className="text-gray-600">R {item.price?.toFixed(2)}</p>
                            
                            <button
                                onClick={() => addToCart(item)}
                                className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
                            >
                                Add to Cart
                            </button>
                        </div>
                    ))}
                </div>
            )}

            <ProductModal 
                product={selectedProduct} 
                isOpen={isModalOpen} 
                onClose={closeModal} 
            />
        </div>
    );
};

export default Wishlist; 