import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { auth } from "../firebase.js";
import { db } from '../firebase.js';
import { collection, getDocs } from "firebase/firestore";

const Dashboard = () => {
    const location = useLocation();
    const [products, setProducts] = useState([]);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const isActiveLink = (path) => {
        return location.pathname === path ? "bg-[#E6D300] font-bold" : "";
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsCollection = collection(db, "products");
                const productsSnapshot = await getDocs(productsCollection);
                const productsData = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                console.log("Fetched products: ", productsData);
                setProducts(productsData);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchProducts();
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };

    return (
        <div className="flex min-h-screen bg-gray-100">
            {/* Mobile Header */}
            <div className="fixed top-0 left-0 right-0 z-20 lg:hidden">
                <div className="bg-[#FEEA00] flex justify-between items-center p-4">
                    <h1 className="text-xl font-bold text-gray-900">Admin Dashboard</h1>
                    <button 
                        onClick={toggleMobileMenu} 
                        className="focus:outline-none"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            {isMobileMenuOpen ? (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            ) : (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            )}
                        </svg>
                    </button>
                </div>
            </div>

            {/* Mobile Sidebar */}
            <aside className={`
                fixed inset-y-0 left-0 z-10 w-64 bg-[#FEEA00] text-gray-900 shadow-lg 
                transform transition-transform duration-300 ease-in-out
                lg:translate-x-0 lg:static lg:block
                ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
            `}>
                <div className="p-4 border-b border-[#E6D300] lg:block hidden">
                    <h1 className="text-xl font-bold">Admin Dashboard</h1>
                </div>
                <nav className="p-4 space-y-2 pt-16 lg:pt-4">
                    <Link 
                        to="/dashboard/products" 
                        onClick={closeMobileMenu}
                        className={`block p-3 rounded-lg transition-colors duration-200 hover:bg-[#E6D300] hover:font-semibold ${isActiveLink("/dashboard/products")}`}
                    >
                        <div className="flex items-center space-x-3">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path>
                            </svg>
                            <span>Products</span>
                        </div>
                    </Link>
                    <Link 
                        to="/dashboard/add-product" 
                        onClick={closeMobileMenu}
                        className={`block p-3 rounded-lg transition-all duration-200 
                            ${isActiveLink("/dashboard/add-product")}
                            hover:bg-[#E6D300] hover:font-semibold
                            focus:ring-2 focus:ring-[#E6D300] focus:ring-offset-2 focus:ring-offset-[#FEEA00]`}
                    >
                        <div className="flex items-center space-x-3">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                            </svg>
                            <span>Add Product</span>
                        </div>
                    </Link>
                    <Link 
                        to="/dashboard/orders" 
                        onClick={closeMobileMenu}
                        className={`block p-3 rounded-lg transition-colors duration-200 hover:bg-[#E6D300] hover:font-semibold ${isActiveLink("/dashboard/orders")}`}
                    >
                        <div className="flex items-center space-x-3">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"></path>
                            </svg>
                            <span>Orders</span>
                        </div>
                    </Link>
                    <Link 
                        to="/dashboard/bulk-upload" 
                        onClick={closeMobileMenu}
                        className={`block p-3 rounded-lg transition-all duration-200 
                            ${isActiveLink("/dashboard/bulk-upload")}
                            hover:bg-[#E6D300] hover:font-semibold
                            focus:ring-2 focus:ring-[#E6D300] focus:ring-offset-2 focus:ring-offset-[#FEEA00]`}
                    >
                        <div className="flex items-center space-x-3">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path>
                            </svg>
                            <span>Bulk Upload</span>
                        </div>
                    </Link>
                    <div className="pt-6 mt-6 border-t border-[#E6D300]">
                        <button 
                            onClick={() => {
                                auth.signOut();
                                closeMobileMenu();
                            }} 
                            className="w-full p-3 rounded-lg transition-colors duration-200 bg-white hover:bg-red-600 hover:text-white flex items-center space-x-3"
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                            </svg>
                            <span>Logout</span>
                        </button>
                    </div>
                </nav>
            </aside>

            {/* Main Content Area */}
            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 lg:ml-0 mt-16 lg:mt-0">
                <div className="container mx-auto px-4 sm:px-6 py-8">
                    <Outlet />
                </div>
            </main>

            {/* Mobile Menu Overlay */}
            {isMobileMenuOpen && (
                <div 
                    className="fixed inset-0 bg-black opacity-50 z-0 lg:hidden"
                    onClick={closeMobileMenu}
                ></div>
            )}
        </div>
    );
};

export default Dashboard;