import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { db } from '../firebase.js';
import { useAuth } from './AuthContext.js';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { enableIndexedDbPersistence } from 'firebase/firestore';
import { toast } from 'react-toastify';

export const WishlistContext = createContext();

export const useWishlist = () => {
    return useContext(WishlistContext);
};

export const WishlistProvider = ({ children }) => {
    const [wishlistItems, setWishlistItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();

    // Enable offline persistence
    useEffect(() => {
        const enablePersistence = async () => {
            try {
                await enableIndexedDbPersistence(db);
            } catch (err) {
                if (err.code === 'failed-precondition') {
                    console.log('Persistence failed: Multiple tabs open');
                } else if (err.code === 'unimplemented') {
                    console.log('Persistence not supported by browser');
                }
            }
        };
        enablePersistence();
    }, []);

    const loadWishlist = useCallback(async () => {
        if (!currentUser) {
            setWishlistItems([]);
            setLoading(false);
            return;
        }
        
        try {
            const wishlistRef = doc(db, 'wishlists', currentUser.uid);
            const wishlistDoc = await getDoc(wishlistRef);
            
            if (wishlistDoc.exists()) {
                const items = wishlistDoc.data().items || [];
                const itemsWithTimestamp = items.map(item => ({
                    ...item,
                    addedAt: item.addedAt || new Date().toISOString()
                }));
                setWishlistItems(itemsWithTimestamp);
            } else {
                await setDoc(wishlistRef, { items: [] });
                setWishlistItems([]);
            }
        } catch (error) {
            console.error('Error loading wishlist:', error);
            toast.error('Failed to load wishlist');
        } finally {
            setLoading(false);
        }
    }, [currentUser]);

    useEffect(() => {
        loadWishlist();
    }, [loadWishlist]);

    const addToWishlist = async (product) => {
        if (!currentUser) {
            toast.warning('Please login to add items to wishlist');
            return;
        }
        
        try {
            if (isInWishlist(product.id)) {
                toast.info('Item already in wishlist');
                return;
            }

            const productWithTimestamp = {
                ...product,
                addedAt: new Date().toISOString()
            };

            const newItems = [...wishlistItems, productWithTimestamp];
            const wishlistRef = doc(db, 'wishlists', currentUser.uid);
            await setDoc(wishlistRef, { items: newItems }, { merge: true });
            setWishlistItems(newItems);
            toast.success(`${product.name} added to wishlist`);
        } catch (error) {
            console.error('Error adding to wishlist:', error);
            toast.error('Failed to add item to wishlist');
            throw error;
        }
    };

    const removeFromWishlist = async (productId) => {
        if (!currentUser) return;
        
        try {
            const newItems = wishlistItems.filter(item => item.id !== productId);
            const wishlistRef = doc(db, 'wishlists', currentUser.uid);
            await setDoc(wishlistRef, { items: newItems }, { merge: true });
            setWishlistItems(newItems);
            toast.success('Item removed from wishlist');
        } catch (error) {
            console.error('Error removing from wishlist:', error);
            toast.error('Failed to remove item from wishlist');
            throw error;
        }
    };

    const clearWishlist = async () => {
        if (!currentUser) return;
        
        try {
            const wishlistRef = doc(db, 'wishlists', currentUser.uid);
            await setDoc(wishlistRef, { items: [] });
            setWishlistItems([]);
            toast.success('Wishlist cleared');
        } catch (error) {
            console.error('Error clearing wishlist:', error);
            toast.error('Failed to clear wishlist');
            throw error;
        }
    };

    const isInWishlist = (productId) => {
        return wishlistItems.some(item => item.id === productId);
    };

    const getWishlistCount = () => wishlistItems.length;

    const value = {
        wishlistItems,
        addToWishlist,
        removeFromWishlist,
        isInWishlist,
        clearWishlist,
        getWishlistCount,
        loading
    };

    return (
        <WishlistContext.Provider value={value}>
            {children}
        </WishlistContext.Provider>
    );
}; 