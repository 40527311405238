import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const PaymentSuccess = () => {
    const location = useLocation();
    const { orderNumber, amount, details } = location.state || {};

    if (!orderNumber) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
                <div className="bg-white p-8 rounded-lg shadow-md text-center">
                    <h1 className="text-2xl font-bold text-red-500 mb-4">Invalid Access</h1>
                    <p>Please complete a purchase to view this page.</p>
                    <Link to="/" className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded">
                        Return to Home
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                <div className="text-center mb-6">
                    <svg className="mx-auto mb-4 h-16 w-16 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <h1 className="text-3xl font-bold text-gray-800">Payment Successful!</h1>
                    <p className="text-gray-600 mt-2">Thank you for your purchase</p>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg mb-4">
                    <div className="flex justify-between mb-2">
                        <span className="text-gray-600">Order Number:</span>
                        <span className="font-semibold">{orderNumber}</span>
                    </div>
                    <div className="flex justify-between mb-2">
                        <span className="text-gray-600">Total Amount:</span>
                        <span className="font-semibold">R {amount.toFixed(2)}</span>
                    </div>
                </div>

                <div className="text-center">
                    <Link 
                        to="/orders" 
                        className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                    >
                        View Order Details
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;