import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useOrder } from '../../contexts/OrderContext.js';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const OrderSuccess = () => {
    const { orderId } = useParams();
    const { getOrder } = useOrder();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                console.log('Fetching order with ID:', orderId);
                const orderData = await getOrder(orderId);
                console.log('Order data fetched:', orderData);
                setOrder(orderData);
            } catch (err) {
                setError('Failed to load order details');
                console.error('Error fetching order:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchOrder();
    }, [orderId, getOrder]);

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-center">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }

    if (error || !order) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-center">
                    <h2 className="text-2xl font-bold text-red-600 mb-4">
                        {error || 'Order not found'}
                    </h2>
                    <Link to="/" className="text-blue-600 hover:text-blue-800">
                        Return to Home
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
            <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-green-600 mb-4">
                    Order Successful!
                </h1>
                <p className="text-gray-600">
                    Thank you for your order. Your order number is #{order.orderNumber}
                </p>
            </div>

            <div className="border-t border-gray-200 pt-6">
                <h2 className="text-xl font-semibold mb-4">Order Details</h2>
                
                {/* Order Items */}
                <div className="space-y-4">
                    {order.items?.map((item, index) => (
                        <div key={index} className="flex justify-between items-center">
                            <div>
                                <p className="font-medium">{item.name}</p>
                                <p className="text-sm text-gray-500">Quantity: {item.quantity}</p>
                            </div>
                            <p className="font-medium">
                                R{(item.price * item.quantity)?.toFixed(2)}
                            </p>
                        </div>
                    ))}
                </div>

                {/* Order Total */}
                <div className="border-t border-gray-200 mt-6 pt-4">
                    <div className="flex justify-between font-bold">
                        <span>Total</span>
                        <span>R{order.total?.toFixed(2)}</span>
                    </div>
                </div>

                {/* Shipping Details */}
                <div className="mt-8">
                    <h3 className="font-semibold mb-2">Shipping Details</h3>
                    <div className="text-gray-600">
                        {order.customerDetails && (
                            <>
                                <p>{order.customerDetails.firstName} {order.customerDetails.lastName}</p>
                                <p>{order.customerDetails.email}</p>
                                <p>{order.customerDetails.phone}</p>
                            </>
                        )}
                        {order.shippingAddress && (
                            <>
                                <p>{order.shippingAddress.street}</p>
                                <p>
                                    {order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.zipCode}
                                </p>
                                <p>{order.shippingAddress.country}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-8 text-center">
                <Link
                    to="/"
                    className="inline-block px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                    Continue Shopping
                </Link>
            </div>
        </div>
    );
};

export default OrderSuccess; 