import React, { useState, useEffect } from "react";
import { db } from '../../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import imageCompression from 'browser-image-compression';
import { FaImage, FaStar, FaArrowUp } from 'react-icons/fa';

const categories = [
    { id: 'clothing', name: 'Clothing' },
    { id: 'groceries', name: 'Groceries' },
    { id: 'shoes', name: 'Shoes' },
    { id: 'spices', name: 'Spices' }
];

const EditProduct = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [stock, setStock] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [category, setCategory] = useState("");
    const [images, setImages] = useState([]);
    const [imagePreview, setImagePreview] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isFeatured, setIsFeatured] = useState(false);
    const [mainImageIndex, setMainImageIndex] = useState(0);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const docRef = doc(db, "products", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setName(data.name);
                    setPrice(data.price);
                    setDescription(data.description);
                    setStock(data.stock);
                    setCategory(data.category || "");
                    setIsFeatured(data.isFeatured || false);
                    if (data.images && data.images.length > 0) {
                        setImages(data.images);
                        setImagePreview(data.images);
                    }
                } else {
                    setError("Product not found");
                }
            } catch (err) {
                setError("Failed to fetch product");
                console.error("Error fetching product:", err);
            }
        };

        fetchProduct();
    }, [id]);

    const updateStock = async (newStock) => {
        const stockNum = parseInt(newStock);
        setStock(stockNum);
    };

    const processImage = async (file) => {
        const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 800,
            useWebWorker: true,
            fileType: 'image/jpeg'
        };

        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error("Error compressing image:", error);
            return file;
        }
    };

    const handleImageChange = async (e) => {
        const files = [...e.target.files];
        
        const processedFiles = await Promise.all(
            files.map(async (file) => {
                return await processImage(file);
            })
        );
        
        setImages(processedFiles);
        const previews = processedFiles.map(file => URL.createObjectURL(file));
        setImagePreview(previews);
    };

    const uploadImages = async (files) => {
        const uploadedUrls = [];
        const totalFiles = files.length;
        let completedUploads = 0;
        
        for (const file of files) {
            const formData = new FormData();
            formData.append("image", file);

            try {
                const response = await axios.post(
                    "https://api.imgbb.com/1/upload?key=e62a6f727b4fc2153542d37d87c723f2",
                    formData,
                    {
                        onUploadProgress: (progressEvent) => {
                            const progress = (progressEvent.loaded / progressEvent.total) * 100;
                            setUploadProgress((completedUploads + progress/100) / totalFiles * 100);
                        }
                    }
                );
                uploadedUrls.push(response.data.data.url);
                completedUploads++;
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }
        
        return uploadedUrls;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        try {
            let imageUrls = images;
            if (images.length > 0 && typeof images[0] !== 'string') {
                imageUrls = await uploadImages(images);
            }

            const productRef = doc(db, "products", id);
            const stockNum = parseInt(stock);
            
            const updateData = {
                name,
                price: parseFloat(price),
                description,
                stock: stockNum,
                category,
                status: stockNum <= 0 ? "Sold Out" : stockNum <= 10 ? "Low Stock" : "In Stock",
                images: imageUrls,
                mainImage: imageUrls[0] || '',
                isFeatured,
                lastUpdated: new Date()
            };

            await updateDoc(productRef, updateData);
            navigate("/dashboard/products");
        } catch (err) {
            setError("Failed to update product");
            console.error("Error updating product:", err);
        } finally {
            setLoading(false);
        }
    };

    const makeMainImage = (index) => {
        if (index === mainImageIndex) return;
        
        const newImages = [...images];
        const newPreviews = [...imagePreview];
        
        const [selectedImage] = newImages.splice(index, 1);
        const [selectedPreview] = newPreviews.splice(index, 1);
        
        newImages.unshift(selectedImage);
        newPreviews.unshift(selectedPreview);
        
        setImages(newImages);
        setImagePreview(newPreviews);
        setMainImageIndex(0);
    };

    return (
        <div className="max-w-2xl mx-auto mt-8 mb-8">
            {error && (
                <div className="mb-4 p-4 text-red-700 bg-red-100 rounded-lg">
                    {error}
                </div>
            )}
            
            <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Product Name
                    </label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Price
                    </label>
                    <input
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                        step="0.01"
                        min="0"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Description
                    </label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        rows="4"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Stock Quantity
                    </label>
                    <input
                        type="number"
                        value={stock}
                        onChange={(e) => updateStock(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                        min="0"
                    />
                    {parseInt(stock) <= 10 && (
                        <p className={`mt-2 text-sm ${parseInt(stock) <= 0 ? 'text-red-600' : 'text-yellow-600'}`}>
                            {parseInt(stock) <= 0 
                                ? 'This product will be marked as Sold Out'
                                : 'Low stock warning'
                            }
                        </p>
                    )}
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Category
                    </label>
                    <select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                    >
                        <option value="">Select a category</option>
                        {categories.map(cat => (
                            <option key={cat.id} value={cat.id}>
                                {cat.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Product Images
                    </label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                            <FaImage className="mx-auto h-12 w-12 text-gray-400" />
                            <div className="flex text-sm text-gray-600">
                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                    <span>Upload images</span>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleImageChange}
                                        className="sr-only"
                                        accept="image/*"
                                    />
                                </label>
                            </div>
                            <p className="text-xs text-gray-500">PNG, JPG up to 200KB</p>
                        </div>
                    </div>

                    {imagePreview.length > 0 && (
                        <div className="mt-4">
                            <p className="text-sm text-gray-600 mb-2">
                                Click the star icon to make an image the main product image
                            </p>
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                {imagePreview.map((preview, index) => (
                                    <div key={index} className="relative group">
                                        <img
                                            src={preview}
                                            alt={`Preview ${index + 1}`}
                                            className="w-full aspect-square object-cover rounded-lg border-2 border-gray-200"
                                        />
                                        <div className="absolute top-2 right-2 flex gap-2">
                                            <button
                                                type="button"
                                                onClick={() => makeMainImage(index)}
                                                className={`p-1.5 rounded-full transition-colors ${
                                                    index === 0
                                                        ? 'bg-yellow-400 text-white'
                                                        : 'bg-gray-100 text-gray-500 hover:bg-yellow-400 hover:text-white'
                                                }`}
                                                title={index === 0 ? 'Main Image' : 'Make Main Image'}
                                            >
                                                <FaStar className="w-4 h-4" />
                                            </button>
                                        </div>
                                        {index === 0 && (
                                            <div className="absolute top-2 left-2 bg-yellow-400 text-xs px-2 py-1 rounded-md text-white">
                                                Main Image
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {uploadProgress > 0 && uploadProgress < 100 && (
                        <div className="mt-2">
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div
                                    className="bg-blue-600 h-2.5 rounded-full"
                                    style={{ width: `${uploadProgress}%` }}
                                ></div>
                            </div>
                            <p className="text-sm text-gray-600 mt-1">
                                Uploading: {uploadProgress.toFixed(0)}%
                            </p>
                        </div>
                    )}
                </div>

                <div className="flex items-center justify-end space-x-4">
                    <button
                        type="button"
                        onClick={() => navigate("/dashboard/products")}
                        className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={loading}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
                    >
                        {loading ? 'Updating...' : 'Update Product'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditProduct;
