import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase.js';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [products, setProducts] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const navigate = useNavigate();
    const searchRef = useRef(null);

    // Fetch all products once when component mounts
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "products"));
                const productsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setProducts(productsData);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, []);

    // Handle click outside to close suggestions
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Update suggestions as user types
    useEffect(() => {
        if (searchTerm.trim() === '') {
            setSuggestions([]);
            return;
        }

        const searchTermLower = searchTerm.toLowerCase();
        const filtered = products
            .filter(product => 
                product.name.toLowerCase().includes(searchTermLower) ||
                product.description?.toLowerCase().includes(searchTermLower) ||
                product.category?.toLowerCase().includes(searchTermLower)
            )
            .slice(0, 5); // Limit to 5 suggestions

        setSuggestions(filtered);
    }, [searchTerm, products]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchTerm.trim()) {
            navigate(`/shop?search=${encodeURIComponent(searchTerm.trim())}`);
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        navigate(`/product/${suggestion.id}`);
        setSearchTerm('');
        setShowSuggestions(false);
    };

    return (
        <div className="relative" ref={searchRef}>
            <form onSubmit={handleSearch} className="flex-1 max-w-xl mx-auto">
                <div className="relative">
                    <input
                        type="text"
                        id="product-search"
                        name="product-search"
                        aria-label="Search products"
                        aria-controls="search-suggestions"
                        aria-autocomplete="list"
                        placeholder="Search products by name, category, or description..."
                        className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setShowSuggestions(true);
                        }}
                        onFocus={() => setShowSuggestions(true)}
                        autoComplete="off"
                    />
                    <button
                        type="submit"
                        className="absolute right-2 top-1/2 -translate-y-1/2 p-2 text-gray-500 hover:text-gray-700"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </button>
                </div>
            </form>

            {/* Suggestions dropdown */}
            {showSuggestions && suggestions.length > 0 && (
                <div 
                    id="search-suggestions"
                    role="listbox"
                    className="absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200"
                >
                    {suggestions.map((suggestion) => (
                        <div
                            key={suggestion.id}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-3"
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            <img 
                                src={suggestion.image || 'https://via.placeholder.com/40?text=No+Image'} 
                                alt={suggestion.name}
                                className="w-10 h-10 object-cover rounded"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = 'https://via.placeholder.com/40?text=No+Image';
                                }}
                            />
                            <div>
                                <div className="font-medium">{suggestion.name}</div>
                                <div className="text-sm text-gray-500">
                                    {suggestion.category} - R{suggestion.price?.toFixed(2)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchBar; 