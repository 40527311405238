import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext.js';
import { CartProvider } from './contexts/CartContext.js';
import { WishlistProvider } from './contexts/WishlistContext.js';
import { OrderProvider } from './contexts/OrderContext.js';

import { NewsletterProvider } from './contexts/NewsletterContext.js';
import Layout from './components/layout/Layout.js';
import PrivateRoute from './components/auth/PrivateRoute.js';

// Import all pages
import Home from './pages/customer/Home.js';
import Shop from './pages/customer/Shop.js';
import ProductDetails from './pages/customer/ProductDetails.js';
import Cart from './pages/customer/Cart.js';
import Checkout from './pages/customer/Checkout.js';
import Profile from './pages/customer/Profile.js';
import Login from './pages/auth/Login.js';
import Signup from './pages/auth/Signup.js';
import OrderSuccess from './pages/customer/OrderSuccess.js';
import Wishlist from './pages/customer/Wishlist.js';
import About from './pages/customer/About';
import PaymentSuccess from './pages/PaymentSuccess';
import ErrorBoundary from './components/ErrorBoundary';

// Admin components
import Dashboard from './components/Dashboard.js';
import AddProduct from './components/admin/AddProduct.js';
import EditProduct from './components/admin/EditProduct.js';
import Orders from './components/admin/Orders.js';
import OrderDetails from './components/admin/OrderDetails.js';
import BulkProductForm from './components/admin/BulkProductForm.js';
import ProductsList from './components/admin/ProductsList.js';

// Notifications
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <AuthProvider>
           
                <NewsletterProvider>
                    <OrderProvider>
                        <WishlistProvider>
                            <CartProvider>
                              <ErrorBoundary>
                                <Layout>
                                    <Routes>
                                        {/* Public Routes */}
                                        <Route path="/" element={<Home />} />
                                        <Route path="/shop" element={<Shop />} />
                                        <Route path="/product/:id" element={<ProductDetails />} />
                                        <Route path="/cart" element={<Cart />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/signup" element={<Signup />} />
                                        <Route path="/checkout" element={<Checkout />} />
                                        <Route path="/order-success/:orderId" element={<OrderSuccess />} />
                                        <Route path="/wishlist" element={<Wishlist />} />
                                        <Route path="/about" element={<About />} />
                                        <Route path="/payment-success" element={<PaymentSuccess />} />

                                        {/* Protected Routes */}
                                        <Route 
                                            path="/profile" 
                                            element={<PrivateRoute><Profile /></PrivateRoute>}
                                        />
                                        <Route 
                                            path="/dashboard" 
                                            element={<PrivateRoute><Dashboard /></PrivateRoute>}
                                        >
                                            <Route index element={<ProductsList />} />
                                            <Route path="products" element={<ProductsList />} />
                                            <Route path="add-product" element={<AddProduct />} />
                                            <Route path="edit-product/:id" element={<EditProduct />} />
                                            <Route path="orders" element={<Orders />} />
                                            <Route path="orders/:orderId" element={<OrderDetails />} />
                                            <Route path="bulk-upload" element={<BulkProductForm />} />
                                        </Route>

                                        {/* 404 Route */}
                                        <Route 
                                            path="*" 
                                            element={
                                                <div className="min-h-screen flex items-center justify-center">
                                                    <div className="text-center">
                                                        <h1 className="text-4xl font-bold text-gray-900 mb-4">404</h1>
                                                        <p className="text-gray-600">Page not found</p>
                                                    </div>
                                                </div>
                                            } 
                                        />
                                    </Routes>
                                </Layout>
                                </ErrorBoundary>
                                <Toaster 
                                    position="top-right"
                                    toastOptions={{
                                        duration: 3000,
                                        style: {
                                            background: '#363636',
                                            color: '#fff',
                                        },
                                    }}
                                />
                                <ToastContainer 
                                    position="top-right"
                                    autoClose={3000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="dark"
                                />
                            </CartProvider>
                        </WishlistProvider>
                    </OrderProvider>
                </NewsletterProvider>
           
        </AuthProvider>
    );
}

export default App;
