import React, { useState, useEffect } from "react";
import { db } from "../../firebase.js";
import { collection, onSnapshot, query, orderBy, updateDoc, doc } from "firebase/firestore";
import OrderDetails from "./OrderDetails.js";

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "orders"), orderBy("createdAt", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const ordersData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                total: Number(doc.data().total) || 0,
                tax: Number(doc.data().tax) || 0,
                shippingCost: Number(doc.data().shippingCost) || 0,
                subtotal: Number(doc.data().subtotal) || 0
            }));

            // Check for new orders
            const newOrders = ordersData.filter(order => 
                !orders.some(existingOrder => existingOrder.id === order.id)
            );

            // Generate notifications for new orders
            const newNotifications = newOrders.map(order => ({
                id: order.id,
                message: `New order received: ${order.orderNumber}`,
                type: 'new_order',
                timestamp: new Date()
            }));

            // Update notifications state
            if (newNotifications.length > 0) {
                setNotifications(prev => [
                    ...newNotifications,
                    ...prev
                ]);
            }

            setOrders(ordersData);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const handleOrderClick = (order) => {
        setSelectedOrder(order);
    };

    const handleStatusUpdate = async (orderId, newStatus) => {
        try {
            const orderRef = doc(db, "orders", orderId);
            await updateDoc(orderRef, { status: newStatus });

            // Create a notification for status change
            const notification = {
                id: `status_${orderId}`,
                message: `Order ${orderId} status updated to ${newStatus}`,
                type: 'status_update',
                timestamp: new Date()
            };

            setNotifications(prev => [notification, ...prev]);
        } catch (error) {
            console.error("Error updating order status:", error);
        }
    };

    const clearNotification = (notificationId) => {
        setNotifications(prev => 
            prev.filter(notification => notification.id !== notificationId)
        );
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="p-6">
            {/* Notification Area */}
            {notifications.length > 0 && (
                <div className="fixed top-4 right-4 z-50 space-y-2">
                    {notifications.map((notification) => (
                        <div 
                            key={notification.id} 
                            className={`
                                p-4 rounded-lg shadow-lg text-sm 
                                ${notification.type === 'new_order' 
                                    ? 'bg-green-100 text-green-800' 
                                    : 'bg-blue-100 text-blue-800'}
                                relative
                            `}
                        >
                            <button 
                                onClick={() => clearNotification(notification.id)}
                                className="absolute top-1 right-1 text-gray-500 hover:text-gray-800"
                            >
                                ✕
                            </button>
                            {notification.message}
                            <div className="text-xs text-gray-500 mt-1">
                                {notification.timestamp.toLocaleTimeString()}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <h2 className="text-2xl font-bold text-gray-800 mb-6">Orders</h2>
            <div className="overflow-x-auto bg-white rounded-lg shadow">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order #</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Items</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {orders.map((order) => (
                            <tr 
                                key={order.id} 
                                className="hover:bg-gray-50 cursor-pointer"
                                onClick={() => handleOrderClick(order)}
                            >
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {order.orderNumber}
                                </td>
                                <td className="px-6 py-4">
                                    <div className="text-sm font-medium text-gray-900">{order.customerName}</div>
                                    <div className="text-sm text-gray-500">{order.email}</div>
                                </td>
                                <td className="px-6 py-4">
                                    {order.items.map((item, index) => (
                                        <div key={index} className="text-sm text-gray-900">
                                            {item.name} x {item.quantity}
                                        </div>
                                    ))}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900">R {order.total.toFixed(2)}</div>
                                    <div className="text-xs text-gray-500">
                                        Tax: R {order.tax.toFixed(2)}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <select 
                                        value={order.status} 
                                        onChange={(e) => handleStatusUpdate(order.id, e.target.value)}
                                        className={`px-2 py-1 text-xs leading-5 font-semibold rounded-full 
                                            ${order.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                                              order.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' : 
                                              'bg-red-100 text-red-800'}`}
                                    >
                                        <option value="Pending">Pending</option>
                                        <option value="Processing">Processing</option>
                                        <option value="Shipped">Shipped</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Cancelled">Cancelled</option>
                                    </select>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {order.createdAt.toDate().toLocaleDateString()}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <button 
                                        onClick={() => handleOrderClick(order)}
                                        className="text-indigo-600 hover:text-indigo-900"
                                    >
                                        View Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selectedOrder && (
                <OrderDetails 
                    order={selectedOrder} 
                    onClose={() => setSelectedOrder(null)} 
                />
            )}
        </div>
    );
};

export default Orders;