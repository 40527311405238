import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';

const PrivateRoute = ({ children }) => {
    const { currentUser } = useAuth();
    const location = useLocation();
    
    return currentUser ? children : <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoute; 