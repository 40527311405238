import React, { useState, useRef, useEffect } from 'react';
import { FaComments, FaTimes } from 'react-icons/fa';
import { collection, addDoc, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase.js';
import { useAuth } from '../../contexts/AuthContext.js';

const ChatWidget = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const { currentUser } = useAuth();
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (isOpen) {
            const q = query(
                collection(db, 'chat_messages'),
                orderBy('timestamp', 'desc'),
                limit(50)
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const messageData = [];
                querySnapshot.forEach((doc) => {
                    messageData.push({ id: doc.id, ...doc.data() });
                });
                setMessages(messageData.reverse());
                scrollToBottom();
            });

            return () => unsubscribe();
        }
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!message.trim()) return;

        try {
            await addDoc(collection(db, 'chat_messages'), {
                text: message,
                userId: currentUser?.uid || 'guest',
                userEmail: currentUser?.email || 'Guest User',
                timestamp: new Date().toISOString(),
                type: 'user'
            });

            setMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <>
            {isOpen ? (
                <div className="fixed bottom-6 right-6 w-80 bg-white rounded-lg shadow-xl z-50">
                    <div className="flex justify-between items-center p-4 bg-blue-600 text-white rounded-t-lg">
                        <h3 className="font-semibold">Chat with Us</h3>
                        <button onClick={() => setIsOpen(false)}>
                            <FaTimes />
                        </button>
                    </div>
                    <div className="h-96 overflow-y-auto p-4 bg-gray-50">
                        {messages.map((msg) => (
                            <div
                                key={msg.id}
                                className={`mb-4 ${
                                    msg.userId === (currentUser?.uid || 'guest')
                                        ? 'text-right'
                                        : 'text-left'
                                }`}
                            >
                                <div
                                    className={`inline-block p-2 rounded-lg ${
                                        msg.userId === (currentUser?.uid || 'guest')
                                            ? 'bg-blue-600 text-white'
                                            : 'bg-gray-200 text-gray-800'
                                    }`}
                                >
                                    {msg.text}
                                </div>
                                <div className="text-xs text-gray-500 mt-1">
                                    {msg.userEmail}
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <form onSubmit={handleSubmit} className="p-4 border-t">
                        <div className="flex gap-2">
                            <input
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Type a message..."
                                className="flex-1 p-2 border rounded"
                            />
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                                Send
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <button
                    onClick={() => setIsOpen(true)}
                    className="fixed bottom-6 right-6 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 z-50"
                >
                    <FaComments className="w-6 h-6" />
                </button>
            )}
        </>
    );
};

export default ChatWidget; 