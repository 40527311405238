import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, query, limit, getDocs, where } from 'firebase/firestore';
import { db } from '../../firebase.js';
import ProductCard from '../../components/products/ProductCard.js';
import { FaArrowUp, FaShoppingCart, FaComments, FaStar, FaSpinner } from 'react-icons/fa';
import { useCart } from '../../contexts/CartContext.js';
import { useNewsletter } from '../../contexts/NewsletterContext.js';
import ChatWidget from '../../components/chat/ChatWidget.js';
import { toast } from 'react-toastify';
import aboutUsImage from '../../assets/images/about-us.jpg';
import clothesImage from '../../assets/images/clothes.jpg';
import foodImage from '../../assets/images/food.jpg';
import pantryImage from '../../assets/images/pantry.jpg';
import shoesImage from '../../assets/images/shoes.jpg';
import ProductModal from '../../components/products/ProductModal';

const Home = () => {
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeLeft, setTimeLeft] = useState({});
    const [showBackToTop, setShowBackToTop] = useState(false);
    const [email, setEmail] = useState('');
    const { cartItems, addToCart } = useCart();
    const { subscribe } = useNewsletter();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    // Sample testimonials data
    const sampleTestimonials = [
        {
            id: '1',
            userName: 'Alice Johnson',
            rating: 5,
            text: 'I absolutely love the products from 1Africa Shop! The quality is amazing and the delivery was super fast.',
        },
        {
            id: '2',
            userName: 'Michael Smith',
            rating: 4,
            text: 'Great selection of authentic African items. I found exactly what I was looking for!',
        },
        {
            id: '3',
            userName: 'Fatima Khan',
            rating: 5,
            text: 'The customer service is outstanding! They helped me with my order and I couldn’t be happier with my purchase.',
        },
    ];

    // Existing Featured Products Fetch
    useEffect(() => {
        const fetchFeaturedProducts = async () => {
            try {
                setLoading(true);
                const q = query(
                    collection(db, "products"),
                    where("isFeatured", "==", true),
                    limit(8)
                );
                const querySnapshot = await getDocs(q);
                const products = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                console.log("Fetched featured products:", products); // Debug log
                setFeaturedProducts(products);
            } catch (err) {
                console.error("Error fetching featured products:", err);
                setError("Failed to load products");
            } finally {
                setLoading(false);
            }
        };

        fetchFeaturedProducts();
    }, []);

    // Countdown Timer Logic - Updated for sale start
    

    // Scroll to Top Logic
    useEffect(() => {
        const handleScroll = () => {
            setShowBackToTop(window.pageYOffset > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();
        try {
            await subscribe(email);
            setEmail('');
            toast.success('Successfully subscribed to newsletter!');
        } catch (error) {
            toast.error(error.message || 'Failed to subscribe. Please try again.');
        }
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    const handleViewMoreClick = () => {
        navigate('/shop');
        window.scrollTo(0, 0);
    };

    const handleAddToCart = (product) => {
        console.log("Adding to cart:", product); // Debugging line
        addToCart(product); // Ensure this function exists and works correctly
        toast.success(`${product.name} added to cart!`); // Feedback for adding to cart
    };

    const handleAddToWishlist = (product) => {
        // Logic to add product to wishlist
        toast.success(`${product.name} added to wishlist!`); // Feedback for adding to wishlist
    };

    // Improved Loading Spinner
    const LoadingSpinner = () => (
        <div className="flex justify-center items-center h-64">
            <FaSpinner className="animate-spin text-blue-500 h-12 w-12" />
            <span className="ml-2 text-lg text-gray-700">Loading products...</span>
        </div>
    );

    // Improved Error Message
    const ErrorMessage = () => (
        <div className="text-center text-red-600 text-lg">
            <p>Oops! Something went wrong while fetching the products.</p>
            <p>Please try refreshing the page.</p>
        </div>
    );

    const TestimonialsSection = () => (
        <section className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold text-center mb-12">Customer Reviews</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {sampleTestimonials.map((review) => (
                        <div key={review.id} className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
                            <div className="flex items-center mb-4">
                                <div className="w-12 h-12 bg-gray-200 rounded-full mr-4 flex items-center justify-center">
                                    {review.userName.charAt(0)}
                                </div>
                                <div>
                                    <div className="font-semibold">{review.userName}</div>
                                    <div className="flex text-yellow-400">
                                        {[...Array(review.rating)].map((_, i) => (
                                            <FaStar key={i} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <p className="text-gray-600">"{review.text}"</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );

   
    return (
        <div className="min-h-screen relative">
            {/* Hero Section */}
            <div className="relative bg-gray-900">
                <div className="absolute inset-0">
                    <img
                        className="w-full h-full object-cover opacity-50"
                        src="https://images.unsplash.com/photo-1550989460-0adf9ea622e2?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                        alt="African Shop"
                    />
                </div>
                <div className="relative max-w-7xl mx-auto py-12 sm:py-24 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold tracking-tight text-white">
                        Welcome to 1Africa Shop
                    </h1>
                    <p className="mt-4 sm:mt-6 text-base sm:text-xl text-gray-300 max-w-3xl">
                        Discover authentic African products, from traditional crafts to modern designs.
                        Shop our curated collection of unique items that celebrate African culture and creativity.
                    </p>
                    <div className="mt-6 sm:mt-10">
                        <Link
                            to="/shop"
                            className="inline-flex items-center px-4 sm:px-6 py-2 sm:py-3 border border-transparent text-sm sm:text-base font-medium rounded-md text-gray-900 bg-[#FEEA00] hover:bg-[#E6D300]"
                        >
                            Shop Now
                        </Link>
                    </div>
                </div>
            </div>

          

            {/* Featured Products Section */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
                <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-900 mb-6 sm:mb-8">
                    Featured Products
                </h2>

                {loading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <ErrorMessage />
                ) : (
                    <div>
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-4">
                            {featuredProducts.map((product) => (
                                <ProductCard 
                                    key={product.id} 
                                    product={product}
                                    onProductClick={handleProductClick}
                                    onAddToCart={handleAddToCart}
                                    onAddToWishlist={handleAddToWishlist}
                                />
                            ))}
                        </div>
                        {/* View More Button */}
                        <div className="mt-6 text-center">
                            <button
                                onClick={handleViewMoreClick}
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-[#FEEA00] hover:bg-[#E6D300] transition duration-300"
                            >
                                View More
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* Add Modal */}
            <ProductModal
                product={selectedProduct}
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedProduct(null);
                }}
            />

            {/* About Us Section */}
            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col md:flex-row items-center gap-8">
                        {/* Image Column */}
                        <div className="w-full md:w-1/2">
                            <img 
                                src={aboutUsImage}
                                alt="African Market Store" 
                                className="rounded-lg shadow-lg w-full h-[400px] object-cover"
                            />
                        </div>

                        {/* Text Column */}
                        <div className="w-full md:w-1/2">
                            <h2 className="text-3xl font-bold mb-4 text-gray-900">About 1Africa Shop</h2>
                            <p className="text-gray-600 mb-4">
                                Welcome to 1Africa Shop, your premier destination for authentic African products. We take pride in bringing you the finest selection of traditional and modern African goods, carefully curated to bring the richness of African culture to your doorstep.
                            </p>
                            <p className="text-gray-600 mb-6">
                                Our mission is to connect African artisans and producers with global customers, ensuring that each purchase not only brings joy to our customers but also supports the talented craftspeople who create these unique pieces.
                            </p>
                            <Link 
                                to="/about"
                                className="inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md text-gray-900 bg-[#FEEA00] hover:bg-[#E6D300] transition duration-300"
                            >
                                Learn More
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* Categories Section - Moved up */}
            <div className="bg-gray-50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    <h2 className="text-3xl font-extrabold text-gray-900 mb-8">
                        Shop by Category
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                        {/* Clothing Category */}
                        <div className="relative group">
                            <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                                <img
                                    src={clothesImage}
                                    alt="African Clothing"
                                    className="h-full w-full object-cover object-center"
                                />
                            </div>
                            <h3 className="mt-6 text-sm text-gray-500">
                                <Link to="/shop?category=clothing">
                                    <span className="absolute inset-0"></span>
                                    Clothing
                                </Link>
                            </h3>
                            <p className="text-base font-semibold text-gray-900">Traditional & Modern African Wear</p>
                        </div>

                        {/* Shoes Category */}
                        <div className="relative group">
                            <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                                <img
                                    src={shoesImage}
                                    alt="African Shoes"
                                    className="h-full w-full object-cover object-center"
                                />
                            </div>
                            <h3 className="mt-6 text-sm text-gray-500">
                                <Link to="/shop?category=shoes">
                                    <span className="absolute inset-0"></span>
                                    Shoes
                                </Link>
                            </h3>
                            <p className="text-base font-semibold text-gray-900">Stylish African Footwear</p>
                        </div>

                        {/* Fresh Food Category */}
                        <div className="relative group">
                            <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                                <img
                                    src={foodImage}
                                    alt="Fresh African Food"
                                    className="h-full w-full object-cover object-center"
                                />
                            </div>
                            <h3 className="mt-6 text-sm text-gray-500">
                                <Link to="/shop?category=fresh-food">
                                    <span className="absolute inset-0"></span>
                                    Fresh Food
                                </Link>
                            </h3>
                            <p className="text-base font-semibold text-gray-900">Fresh African Delicacies</p>
                        </div>

                        {/* Groceries Category */}
                        <div className="relative group">
                            <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                                <img
                                    src={pantryImage}
                                    alt="African Groceries"
                                    className="h-full w-full object-cover object-center"
                                />
                            </div>
                            <h3 className="mt-6 text-sm text-gray-500">
                                <Link to="/shop?category=groceries">
                                    <span className="absolute inset-0"></span>
                                    Groceries
                                </Link>
                            </h3>
                            <p className="text-base font-semibold text-gray-900">African Pantry Essentials</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Testimonials Section - Now after categories */}
            <TestimonialsSection />

            {/* Newsletter Section */}
            <div className="bg-gradient-to-r from-pink-500 to-orange-500 text-white py-12 sm:py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-2xl sm:text-3xl font-bold mb-4">Stay Updated on Deals!</h2>
                    <p className="mb-6 sm:mb-8">Subscribe to our newsletter for exclusive offers and updates.</p>
                    <form onSubmit={handleSubscribe} className="max-w-md mx-auto flex flex-col sm:flex-row gap-4">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            className="flex-1 px-4 py-2 rounded text-gray-800 w-full"
                            required
                        />
                        <button
                            type="submit"
                            className="bg-[#FEEA00] px-6 py-2 rounded hover:bg-[#E6D300] text-gray-900 w-full sm:w-auto"
                        >
                            Subscribe
                        </button>
                    </form>
                </div>
            </div>

            {/* Floating Elements */}
            {showBackToTop && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-20 sm:bottom-24 right-4 sm:right-6 bg-[#FEEA00] text-gray-900 p-2 sm:p-3 rounded-full shadow-lg hover:bg-[#E6D300] transition-colors z-50"
                >
                    <FaArrowUp className="w-4 h-4 sm:w-6 sm:h-6" />
                </button>
            )}

            <div className="fixed bottom-4 sm:bottom-6 right-4 sm:right-6 bg-[#D70100] text-white p-2 sm:p-3 rounded-full shadow-lg hover:bg-[#B30000] cursor-pointer z-50">
                <FaComments className="w-4 h-4 sm:w-6 sm:h-6" />
            </div>

            <Link to="/cart" className="fixed top-20 sm:top-24 right-4 sm:right-6 bg-[#FEEA00] text-gray-900 p-2 sm:p-3 rounded-full shadow-lg hover:bg-[#E6D300] z-50">
                <div className="relative">
                    <FaShoppingCart className="w-4 h-4 sm:w-6 sm:h-6" />
                    {cartItems.length > 0 && (
                        <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-4 h-4 sm:w-5 sm:h-5 flex items-center justify-center">
                            {cartItems.length}
                        </span>
                    )}
                </div>
            </Link>

            <ChatWidget />
        </div>
    );
};

export default Home;
