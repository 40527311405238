import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { FaPlus, FaTrash, FaSpinner, FaImage } from 'react-icons/fa';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

const BulkProductForm = () => {
    const [products, setProducts] = useState([{
        name: '',
        price: '',
        description: '',
        stock: '',
        category: '',
        imageUrl: '',
        isFeatured: false
    }]);
    const [uploading, setUploading] = useState(false);
    const [status, setStatus] = useState(null);
    const [imageFiles, setImageFiles] = useState({});
    const [imagePreview, setImagePreview] = useState({});

    const categories = [
        { id: 'clothing', name: 'Clothing' },
        { id: 'groceries', name: 'Groceries' },
        { id: 'shoes', name: 'Shoes' },
        { id: 'spices', name: 'Spices' }
    ];

    const addProductField = () => {
        setProducts([...products, {
            name: '',
            price: '',
            description: '',
            stock: '',
            category: '',
            imageUrl: '',
            isFeatured: false
        }]);
    };

    const removeProductField = (index) => {
        const newProducts = products.filter((_, i) => i !== index);
        setProducts(newProducts);
    };

    const handleChange = (index, field, value) => {
        const newProducts = [...products];
        newProducts[index] = {
            ...newProducts[index],
            [field]: value
        };
        setProducts(newProducts);
    };

    const processImage = async (file) => {
        const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 800,
            useWebWorker: true,
            fileType: 'image/jpeg'
        };

        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Error compressing image:", error);
            return file;
        }
    };

    const handleImageChange = async (index, e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            const processedFile = await processImage(file);
            setImageFiles(prev => ({ ...prev, [index]: processedFile }));
            setImagePreview(prev => ({ 
                ...prev, 
                [index]: URL.createObjectURL(processedFile) 
            }));
        } catch (error) {
            console.error("Error handling image:", error);
        }
    };

    const uploadImage = async (file) => {
        if (!file) return null;
        
        const formData = new FormData();
        formData.append("image", file);

        try {
            const response = await axios.post(
                "https://api.imgbb.com/1/upload?key=e62a6f727b4fc2153542d37d87c723f2",
                formData
            );
            return response.data.data.url;
        } catch (error) {
            console.error("Error uploading image:", error);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploading(true);
        setStatus(null);

        try {
            let successful = 0;
            let failed = 0;

            for (let i = 0; i < products.length; i++) {
                const product = products[i];
                try {
                    if (!product.name || !product.price) {
                        failed++;
                        continue;
                    }

                    // Upload image if exists
                    let imageUrl = null;
                    if (imageFiles[i]) {
                        imageUrl = await uploadImage(imageFiles[i]);
                    }

                    const formattedProduct = {
                        name: product.name.trim(),
                        price: parseFloat(product.price),
                        description: product.description.trim(),
                        stock: parseInt(product.stock) || 0,
                        category: product.category.toLowerCase(),
                        images: imageUrl ? [imageUrl] : [],
                        mainImage: imageUrl || '',
                        isFeatured: product.isFeatured,
                        createdAt: new Date().toISOString()
                    };

                    await addDoc(collection(db, 'products'), formattedProduct);
                    successful++;
                } catch (err) {
                    console.error('Error adding product:', err);
                    failed++;
                }
            }

            setStatus({ successful, failed });
            if (successful > 0) {
                // Reset form
                setProducts([{ name: '', price: '', description: '', stock: '', category: '', imageUrl: '', isFeatured: false }]);
                setImageFiles({});
                setImagePreview({});
            }
        } catch (err) {
            console.error('Error in bulk upload:', err);
            setStatus({ error: 'Failed to upload products' });
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6">
            <div className="bg-white rounded-lg shadow-md p-6">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">Add Multiple Products</h2>
                    <button
                        onClick={addProductField}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 flex items-center gap-2"
                        type="button"
                    >
                        <FaPlus /> Add Product
                    </button>
                </div>

                <form onSubmit={handleSubmit}>
                    {products.map((product, index) => (
                        <div 
                            key={index}
                            className="mb-6 p-4 border rounded-lg bg-gray-50 relative"
                        >
                            <div className="absolute right-2 top-2">
                                {products.length > 1 && (
                                    <button
                                        type="button"
                                        onClick={() => removeProductField(index)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        <FaTrash />
                                    </button>
                                )}
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Product Name *
                                    </label>
                                    <input
                                        type="text"
                                        value={product.name}
                                        onChange={(e) => handleChange(index, 'name', e.target.value)}
                                        className="w-full p-2 border rounded"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Price *
                                    </label>
                                    <input
                                        type="number"
                                        value={product.price}
                                        onChange={(e) => handleChange(index, 'price', e.target.value)}
                                        className="w-full p-2 border rounded"
                                        step="0.01"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Stock
                                    </label>
                                    <input
                                        type="number"
                                        value={product.stock}
                                        onChange={(e) => handleChange(index, 'stock', e.target.value)}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Category
                                    </label>
                                    <select
                                        value={product.category}
                                        onChange={(e) => handleChange(index, 'category', e.target.value)}
                                        className="w-full p-2 border rounded"
                                    >
                                        <option value="">Select Category</option>
                                        {categories.map((cat) => (
                                            <option key={cat.id} value={cat.id}>
                                                {cat.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="md:col-span-2">
                                    <label className="block text-sm font-medium mb-1">
                                        Product Image
                                    </label>
                                    <div className="flex items-center space-x-4">
                                        <div className="flex-1">
                                            <input
                                                type="file"
                                                onChange={(e) => handleImageChange(index, e)}
                                                accept="image/*"
                                                className="hidden"
                                                id={`image-upload-${index}`}
                                            />
                                            <label
                                                htmlFor={`image-upload-${index}`}
                                                className="flex items-center justify-center w-full p-2 border-2 border-dashed rounded-lg cursor-pointer hover:bg-gray-50"
                                            >
                                                <div className="flex flex-col items-center">
                                                    <FaImage className="w-8 h-8 text-gray-400 mb-1" />
                                                    <span className="text-sm text-gray-500">
                                                        Click to upload image
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        {imagePreview[index] && (
                                            <div className="w-24 h-24 relative">
                                                <img
                                                    src={imagePreview[index]}
                                                    alt="Preview"
                                                    className="w-full h-full object-contain border rounded bg-gray-50"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <p className="mt-1 text-xs text-gray-500">
                                        Recommended: 800x800px, max 200KB
                                    </p>
                                </div>

                                <div className="md:col-span-2">
                                    <label className="block text-sm font-medium mb-1">
                                        Description
                                    </label>
                                    <textarea
                                        value={product.description}
                                        onChange={(e) => handleChange(index, 'description', e.target.value)}
                                        className="w-full p-2 border rounded"
                                        rows="2"
                                    />
                                </div>

                                <div className="md:col-span-2">
                                    <label className="flex items-center gap-2">
                                        <input
                                            type="checkbox"
                                            checked={product.isFeatured}
                                            onChange={(e) => handleChange(index, 'isFeatured', e.target.checked)}
                                            className="rounded"
                                        />
                                        <span className="text-sm font-medium">Featured Product</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}

                    <button
                        type="submit"
                        disabled={uploading}
                        className={`w-full py-3 rounded-lg text-white font-medium ${
                            uploading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
                        } flex items-center justify-center gap-2`}
                    >
                        {uploading ? (
                            <>
                                <FaSpinner className="animate-spin" />
                                Uploading...
                            </>
                        ) : (
                            'Upload Products'
                        )}
                    </button>
                </form>

                {status && (
                    <div className={`mt-4 p-4 rounded-lg ${
                        status.error ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
                    }`}>
                        {status.error ? (
                            <p>{status.error}</p>
                        ) : (
                            <p>
                                Successfully uploaded {status.successful} products
                                {status.failed > 0 && ` (${status.failed} failed)`}
                            </p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BulkProductForm; 