import React, { useState } from 'react';
import { FaHeart, FaRegHeart, FaEye } from 'react-icons/fa';
import { useCart } from '../../contexts/CartContext.js';
import { useWishlist } from '../../contexts/WishlistContext.js';

const ProductCard = ({ product, onProductClick, onAddToCart, onAddToWishlist }) => {
    const { addToCart, removeFromCart } = useCart();
    const { addToWishlist, removeFromWishlist, isInWishlist } = useWishlist();
    const [addedToCart, setAddedToCart] = useState(false);  // Track button state

    const handleAddToCart = (e) => {
        e.stopPropagation();
        if (addedToCart) {
            removeFromCart(product.id); // Remove from cart if already added
            setAddedToCart(false); // Toggle back to "Add to Cart"
        } else {
            addToCart({
                id: product.id,
                name: product.name,
                price: product.price,
                image: product.images?.[0] || product.mainImage,
                quantity: 1
            });
            setAddedToCart(true); // Set addedToCart to true on click
        }
    };

    const handleWishlistToggle = (e) => {
        e.stopPropagation();
        if (isInWishlist(product.id)) {
            removeFromWishlist(product.id);
        } else {
            addToWishlist(product);
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 w-full sm:w-64"> {/* Responsive width */}
            {/* Product Container */}
            <div className="relative group">
                {/* Image Container */}
                <div 
                    className="relative w-full pt-[100%] overflow-hidden rounded-t-lg cursor-pointer bg-white"
                    onClick={() => onProductClick(product)}
                >
                    <img
                        src={product.images?.[0] || product.mainImage}
                        alt={product.name}
                        className="absolute inset-0 w-full h-full object-cover p-2 transform group-hover:scale-105 transition-transform duration-200"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://via.placeholder.com/400?text=No+Image';
                        }}
                    />
                    
                    {/* Quick Actions Overlay */}
                    <div className="absolute top-2 right-2 flex flex-col gap-2 opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-200">
                        <button
                            onClick={handleWishlistToggle}
                            className="p-2 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors"
                            title={isInWishlist(product.id) ? "Remove from Wishlist" : "Add to Wishlist"}
                        >
                            {isInWishlist(product.id) ? (
                                <FaHeart className="w-4 h-4 text-red-500" />
                            ) : (
                                <FaRegHeart className="w-4 h-4 text-gray-600" />
                            )}
                        </button>
                        <button
                            onClick={() => onProductClick(product)}
                            className="p-2 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors"
                            title="Quick View"
                        >
                            <FaEye className="w-4 h-4 text-gray-600" />
                        </button>
                    </div>

                    {/* Stock Badge */}
                    {product.stock <= 0 && (
                        <div className="absolute top-2 left-2 px-2 py-1 bg-red-500 text-white text-xs rounded-md">
                            Out of Stock
                        </div>
                    )}
                </div>

                {/* Product Info */}
                <div className="p-3">
                    <h3 className="text-sm font-medium text-gray-800 mb-1 truncate" title={product.name}>
                        {product.name}
                    </h3>
                    <p className="text-lg font-semibold text-gray-900 mb-2">
                        R {product.price?.toFixed(2)}
                    </p>

                    {/* Add to Cart Button */}
                    {product.stock > 0 ? (
                        <button
                            onClick={handleAddToCart}
                            className={`w-full py-1.5 rounded-md text-sm font-medium transition-colors duration-200 ${addedToCart ? 'bg-red-500 text-white' : 'bg-[#FEEA00] hover:bg-[#E6D300] text-gray-900'}`}
                        >
                            {addedToCart ? 'Remove from Cart' : 'Add to Cart'}
                        </button>
                    ) : (
                        <button
                            disabled
                            className="w-full bg-gray-100 text-gray-500 py-1.5 rounded-md text-sm font-medium cursor-not-allowed"
                        >
                            Out of Stock
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
