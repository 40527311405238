import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext.js';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase.js';

const Profile = () => {
    const { currentUser } = useAuth();
    const [profile, setProfile] = useState(null);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [activeTab, setActiveTab] = useState('profile'); // 'profile' or 'orders'

    useEffect(() => {
        const fetchProfileAndOrders = async () => {
            try {
                // Fetch user profile
                const userDoc = await getDoc(doc(db, "users", currentUser.uid));
                if (userDoc.exists()) {
                    setProfile(userDoc.data());
                }

                // Fetch user orders
                const ordersQuery = query(
                    collection(db, "orders"),
                    where("userId", "==", currentUser.uid)
                );
                const ordersSnapshot = await getDocs(ordersQuery);
                const ordersData = ordersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setOrders(ordersData);
            } catch (err) {
                console.error('Error fetching profile:', err);
                setError('Failed to load profile information');
            } finally {
                setLoading(false);
            }
        };

        fetchProfileAndOrders();
    }, [currentUser]);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="bg-white shadow rounded-lg">
                {/* Tabs */}
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex">
                        <button
                            onClick={() => setActiveTab('profile')}
                            className={`${
                                activeTab === 'profile'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
                        >
                            Profile Information
                        </button>
                        <button
                            onClick={() => setActiveTab('orders')}
                            className={`${
                                activeTab === 'orders'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
                        >
                            Order History
                        </button>
                    </nav>
                </div>

                {/* Content */}
                <div className="p-6">
                    {error && (
                        <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                            <span className="block sm:inline">{error}</span>
                        </div>
                    )}

                    {activeTab === 'profile' && profile && (
                        <div className="space-y-6">
                            <div>
                                <h3 className="text-lg font-medium text-gray-900">Personal Information</h3>
                                <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Name</label>
                                        <p className="mt-1 text-sm text-gray-900">
                                            {profile.firstName} {profile.lastName}
                                        </p>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Email</label>
                                        <p className="mt-1 text-sm text-gray-900">{profile.email}</p>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Phone</label>
                                        <p className="mt-1 text-sm text-gray-900">{profile.phone}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 'orders' && (
                        <div>
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Order History</h3>
                            {orders.length === 0 ? (
                                <p className="text-gray-500">No orders found.</p>
                            ) : (
                                <div className="space-y-6">
                                    {orders.map((order) => (
                                        <div key={order.id} className="bg-gray-50 p-4 rounded-lg">
                                            <div className="flex justify-between items-start">
                                                <div>
                                                    <h4 className="text-sm font-medium text-gray-900">
                                                        Order #{order.id}
                                                    </h4>
                                                    <p className="text-sm text-gray-500">
                                                        {new Date(order.createdAt?.toDate()).toLocaleDateString()}
                                                    </p>
                                                </div>
                                                <span className={`px-2 py-1 text-xs font-medium rounded-full
                                                    ${order.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                                                      order.status === 'Completed' ? 'bg-green-100 text-green-800' :
                                                      'bg-gray-100 text-gray-800'}`}>
                                                    {order.status}
                                                </span>
                                            </div>
                                            <div className="mt-4">
                                                <h5 className="text-sm font-medium text-gray-900 mb-2">Items:</h5>
                                                <ul className="space-y-2">
                                                    {order.items.map((item, index) => (
                                                        <li key={index} className="text-sm text-gray-600">
                                                            {item.quantity}x {item.name} - R{item.price.toFixed(2)}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="mt-4 pt-4 border-t border-gray-200">
                                                <div className="flex justify-between text-sm">
                                                    <span className="font-medium text-gray-900">Total:</span>
                                                    <span className="font-medium text-gray-900">
                                                        R{order.total.toFixed(2)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Profile; 