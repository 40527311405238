import React, { useState } from "react";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import axios from "axios";
import imageCompression from 'browser-image-compression';
import { FaImage, FaStar, FaArrowUp, FaTimes } from 'react-icons/fa';

const AddProduct = () => {
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [stock, setStock] = useState("");
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState("");
    const [isFeatured, setIsFeatured] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [imagePreview, setImagePreview] = useState([]);
    const [mainImageIndex, setMainImageIndex] = useState(0);

    const [sizes, setSizes] = useState([]);
    const [currentSize, setCurrentSize] = useState("");
    const [sizeStock, setSizeStock] = useState("");

    const categories = [
        { id: 'clothing', name: 'Clothing' },
        { id: 'groceries', name: 'Groceries' },
        { id: 'shoes', name: 'Shoes' },
        { id: 'spices', name: 'Spices' }
    ];


    // Predefined size options for different categories
    const sizeOptions = {
        clothing: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
        shoes: ['US 6', 'US 7', 'US 8', 'US 9', 'US 10', 'US 11', 'US 12']
    };

    const addSize = () => {
        if (currentSize && sizeStock) {
            // Check if size already exists
            const sizeExists = sizes.some(s => s.size === currentSize);
            
            if (sizeExists) {
                alert("This size has already been added.");
                return;
            }

            setSizes([
                ...sizes, 
                { 
                    size: currentSize, 
                    stock: parseInt(sizeStock, 10) 
                }
            ]);
            
            // Reset size inputs
            setCurrentSize("");
            setSizeStock("");
        }
    };

    const removeSize = (sizeToRemove) => {
        setSizes(sizes.filter(s => s.size !== sizeToRemove));
    };
    
    const processImage = async (file) => {
        const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 800,
            useWebWorker: true,
            fileType: 'image/jpeg'
        };

        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error("Error compressing image:", error);
            return file;
        }
    };

    const handleImageChange = async (e) => {
        const files = [...e.target.files];
        
        const processedFiles = await Promise.all(
            files.map(async (file) => {
                return await processImage(file);
            })
        );
        
        setImages(processedFiles);

        const previews = processedFiles.map(file => URL.createObjectURL(file));
        setImagePreview(previews);
    };

    const uploadImages = async (files) => {
        const uploadedUrls = [];
        const totalFiles = files.length;
        let completedUploads = 0;
        
        for (const file of files) {
            const formData = new FormData();
            formData.append("image", file);

            try {
                console.log("Uploading image:", file.name);
                const response = await axios.post(
                    "https://api.imgbb.com/1/upload?key=e62a6f727b4fc2153542d37d87c723f2",
                    formData,
                    {
                        onUploadProgress: (progressEvent) => {
                            const progress = (progressEvent.loaded / progressEvent.total) * 100;
                            console.log(`Upload progress for ${file.name}: ${progress}%`);
                        }
                    }
                );

                if (response.data.success) {
                    console.log("Upload successful:", response.data.data.url);
                    uploadedUrls.push(response.data.data.url);
                    completedUploads++;
                    setUploadProgress((completedUploads / totalFiles) * 100);
                }
            } catch (error) {
                console.error("Error uploading image:", file.name, error);
                alert(`Failed to upload image: ${file.name}`);
            }
        }
        return uploadedUrls;
    };

    const makeMainImage = (index) => {
        if (index === mainImageIndex) return;
        
        const newImages = [...images];
        const newPreviews = [...imagePreview];
        
        const [selectedImage] = newImages.splice(index, 1);
        const [selectedPreview] = newPreviews.splice(index, 1);
        
        newImages.unshift(selectedImage);
        newPreviews.unshift(selectedPreview);
        
        setImages(newImages);
        setImagePreview(newPreviews);
        setMainImageIndex(0);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setUploadProgress(0);

        try {
            if (images.length === 0) {
                throw new Error("Please select at least one image");
            }

            // Validate sizes for clothing category
            if (category === 'clothing' && sizes.length === 0) {
                throw new Error("Please add at least one size for clothing products");
            }

            const imageUrls = await uploadImages(images);

            if (imageUrls.length === 0) {
                throw new Error("Failed to upload images");
            }

            const productData = {
                name,
                price: parseFloat(price),
                description,
                // Total stock will be the sum of all size stocks or the single stock value
                stock: category === 'clothing' 
                    ? sizes.reduce((total, s) => total + s.stock, 0)
                    : parseInt(stock, 10),
                mainImage: imageUrls[0],
                images: imageUrls,
                category,
                isFeatured,
                // Add sizes only for clothing category
                ...(category === 'clothing' && { sizes }),
                createdAt: new Date().toISOString(),
            };

            await addDoc(collection(db, "products"), productData);

            // Reset all form fields
            setName("");
            setPrice("");
            setDescription("");
            setStock("");
            setImages([]);
            setImagePreview([]);
            setCategory("");
            setIsFeatured(false);
            setSizes([]); // Reset sizes
            setUploadProgress(0);
            alert("Product added successfully!");
        } catch (error) {
            console.error("Error adding product:", error);
            alert(error.message || "Failed to add product. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
<form onSubmit={handleSubmit} className="p-4 bg-white shadow rounded">
            <h2 className="text-xl font-bold mb-4">Add New Product</h2>
            <div className="mb-4">
                <label className="block font-semibold">Product Name</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block font-semibold">Price (R)</label>
                <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block font-semibold">Description</label>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block font-semibold">Category</label>
                <select
                    value={category}
                    onChange={(e) => {
                        setCategory(e.target.value);
                        // Reset sizes when category changes
                        setSizes([]);
                    }}
                    className="w-full p-2 border rounded"
                    required
                >
                    <option value="">Select a category</option>
                    {categories.map(cat => (
                        <option key={cat.id} value={cat.id}>
                            {cat.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Conditional Size Input for Clothing and Shoes */}
            {(category === 'clothing' || category === 'shoes') && (
                <div className="mb-4">
                    <label className="block font-semibold">Product Sizes</label>
                    <div className="flex space-x-2 mb-2">
                        <select
                            value={currentSize}
                            onChange={(e) => setCurrentSize(e.target.value)}
                            className="w-1/2 p-2 border rounded"
                        >
                            <option value="">Select Size</option>
                            {sizeOptions[category]?.map(size => (
                                <option key={size} value={size}>{size}</option>
                            ))}
                        </select>
                        <input
                            type="number"
                            value={sizeStock}
                            onChange={(e) => setSizeStock(e.target.value)}
                            placeholder="Stock Quantity"
                            className="w-1/2 p-2 border rounded"
                        />
                        <button
                            type="button"
                            onClick={addSize}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            disabled={!currentSize || !sizeStock}
                        >
                            Add Size
                        </button>
                    </div>

                    {/* Sizes Display */}
                    {sizes.length > 0 && (
                        <div className="mt-2">
                            <p className="text-sm font-semibold mb-1">Added Sizes:</p>
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                                {sizes.map((sizeItem) => (
                                    <div 
                                        key={sizeItem.size} 
                                        className="flex justify-between items-center bg-gray-100 p-2 rounded"
                                    >
                                        <span>{sizeItem.size} - Stock: {sizeItem.stock}</span>
                                        <button
                                            type="button"
                                            onClick={() => removeSize(sizeItem.size)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <FaTimes />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* If category is NOT clothing or shoes, use original stock input */}
            {!(category === 'clothing' || category === 'shoes') && (
                <div className="mb-4">
                    <label className="block font-semibold">Stock Quantity</label>
                    <input
                        type="number"
                        value={stock}
                        onChange={(e) => setStock(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    />
                </div>
            )}

            <div className="mb-4">
                <label className="block font-semibold">Product Images</label>
                <input
                    type="file"
                    multiple
                    onChange={handleImageChange}
                    className="w-full p-2 border rounded"
                    accept="image/*"
                    required
                />
                <div className="mt-2 text-sm text-gray-600">
                    <p>Recommended image specifications:</p>
                    <ul className="list-disc pl-5">
                        <li>Square format (1:1 aspect ratio)</li>
                        <li>800x800 pixels (recommended)</li>
                        <li>White or transparent background</li>
                        <li>Maximum file size: 200KB</li>
                        <li>Formats: JPEG or PNG</li>
                    </ul>
                </div>

                {imagePreview.length > 0 && (
                    <div className="mt-4">
                        <p className="text-sm text-gray-600 mb-2">
                            Click the star icon to make an image the main product image
                        </p>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                            {imagePreview.map((preview, index) => (
                                <div key={index} className="relative group">
                                    <img
                                        src={preview}
                                        alt={`Preview ${index + 1}`}
                                        className="w-full aspect-square object-cover rounded-lg border-2 border-gray-200"
                                    />
                                    <div className="absolute top-2 right-2 flex gap-2">
                                        <button
                                            type="button"
                                            onClick={() => makeMainImage(index)}
                                            className={`p-1.5 rounded-full transition-colors ${
                                                index === 0
                                                    ? 'bg-yellow-400 text-white'
                                                    : 'bg-gray-100 text-gray-500 hover:bg-yellow-400 hover:text-white'
                                            }`}
                                            title={index === 0 ? 'Main Image' : 'Make Main Image'}
                                        >
                                            <FaStar className="w-4 h-4" />
                                        </button>
                                    </div>
                                    {index === 0 && (
                                        <div className="absolute top-2 left-2 bg-yellow-400 text-xs px-2 py-1 rounded-md text-white">
                                            Main Image
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="mt-2">
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                            <div
                                className="bg-blue-600 h-2.5 rounded-full"
                                style={{ width: `${uploadProgress}%` }}
                            ></div>
                        </div>
                        <p className="text-sm text-gray-600 mt-1">
                            Uploading: {uploadProgress.toFixed(0)}%
                        </p>
                    </div>
                )}
            </div>
            <div className="mb-4">
                <label className="block font-semibold">Category</label>
                <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                >
                    <option value="">Select a category</option>
                    {categories.map(cat => (
                        <option key={cat.id} value={cat.id}>
                            {cat.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mb-4">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={isFeatured}
                        onChange={(e) => setIsFeatured(e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-500"
                    />
                    <span className="font-semibold">Feature this product on homepage</span>
                </label>
                <p className="text-sm text-gray-500 mt-1">
                    Featured products will be displayed in the Featured Products section on the homepage
                </p>
            </div>
            <button
                type="submit"
                className={`w-full bg-blue-500 text-white px-4 py-2 rounded ${
                    loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
                }`}
                disabled={loading}
            >
                {loading ? "Adding Product..." : "Add Product"}
            </button>
        </form>
    );
};

export default AddProduct;
