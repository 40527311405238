import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaShippingFast, FaHandshake, FaGlobe, FaHeart } from 'react-icons/fa';
import aboutUsImage from '../../assets/images/about-us.jpg';
import aboutUsHeroImage from '../../assets/images/abou-us-v2.png';
import ankaraImage from '../../assets/images/ankara.jpeg';
import kaftanImage from '../../assets/images/kaftan.jpeg';
import dashikiImage from '../../assets/images/dashiki.jpeg';

const About = () => {
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="min-h-screen bg-white">
            {/* Hero Section */}
            <div className="relative bg-gray-900 py-16">
                <div className="absolute inset-0">
                    <img
                        className="w-full h-full object-cover opacity-40"
                        src={aboutUsHeroImage}
                        alt="African Market"
                    />
                </div>
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
                        Our Story
                    </h1>
                    <p className="mt-6 text-xl text-gray-300 max-w-3xl mx-auto">
                        Bringing authentic African products to your doorstep since 2024
                    </p>
                </div>
            </div>

            {/* Mission Section */}
            <div className="py-16 bg-gradient-to-b from-white to-gray-50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
                        <h2 className="text-3xl font-bold text-gray-900 mb-6 border-b-2 border-[#FEEA00] pb-4">
                            Our Mission
                        </h2>
                        <p className="mt-4 text-lg leading-relaxed text-gray-800 font-medium">
                            At <span className="text-[#FEEA00] font-bold">At 1 Africa Shop ( Nqoba Jay ( Pty ) Ltd )</span>, we're dedicated to bridging cultures through commerce. 
                            Our mission is to bring authentic African products to the global market while 
                            supporting local artisans and preserving traditional craftsmanship.
                        </p>
                    </div>
                </div>
            </div>

            {/* Values Section */}
            <div className="bg-gray-50 py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">Our Values</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {[
                            {
                                icon: <FaHandshake className="h-8 w-8" />,
                                title: "Authenticity",
                                description: "We ensure all our products are genuinely sourced from African artisans and producers."
                            },
                            {
                                icon: <FaHeart className="h-8 w-8" />,
                                title: "Quality",
                                description: "Each product is carefully selected to meet our high standards of quality and craftsmanship."
                            },
                            {
                                icon: <FaGlobe className="h-8 w-8" />,
                                title: "Cultural Bridge",
                                description: "We connect African creators with global customers, fostering cultural exchange."
                            },
                            {
                                icon: <FaShippingFast className="h-8 w-8" />,
                                title: "Reliability",
                                description: "Fast shipping and excellent customer service are at the heart of what we do."
                            }
                        ].map((value, index) => (
                            <div 
                                key={index} 
                                className="text-center p-6 bg-white rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl hover:bg-[#FEEA00]/5 cursor-pointer"
                            >
                                <div className="text-[#FEEA00] mx-auto mb-4 transform transition-transform duration-300 group-hover:scale-110">
                                    {value.icon}
                                </div>
                                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                                    {value.title}
                                </h3>
                                <p className="text-gray-600">
                                    {value.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Team/Story Section */}
            <div className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col lg:flex-row items-center gap-12">
                        <div className="lg:w-1/2">
                            <img 
                                src={aboutUsImage}
                                alt="Our Team" 
                                className="rounded-lg shadow-xl w-full h-[400px] object-cover mb-8 lg:mb-0"
                            />
                            {/* African Fashion Gallery */}
                            <div className="grid grid-cols-3 gap-4 mt-6">
                                <img 
                                    src={ankaraImage} 
                                    alt="Ankara Fashion" 
                                    className="rounded-lg h-32 w-full object-cover hover:scale-105 transition-transform duration-300"
                                />
                                <img 
                                    src={kaftanImage} 
                                    alt="Kaftan Style" 
                                    className="rounded-lg h-32 w-full object-cover hover:scale-105 transition-transform duration-300"
                                />
                                <img 
                                    src={dashikiImage} 
                                    alt="Dashiki Design" 
                                    className="rounded-lg h-32 w-full object-cover hover:scale-105 transition-transform duration-300"
                                />
                            </div>
                        </div>
                        <div className="lg:w-1/2">
                            <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Journey</h2>
                            <p className="text-gray-600 mb-4">
                                1Africa Shop started with a simple idea: to create a platform that showcases 
                                the beauty and diversity of African craftsmanship to the world. Our journey 
                                began when we recognized the gap between talented African artisans and the 
                                global market.
                            </p>
                            <p className="text-gray-600 mb-6">
                                Today, we work directly with artisans and producers across Africa, ensuring 
                                fair trade practices and supporting local communities. Each product in our 
                                collection tells a story of tradition, creativity, and excellence.
                            </p>
                            
                            {/* Added African Fashion Details */}
                            <div className="bg-gray-50 p-6 rounded-lg mb-6">
                                <h3 className="text-xl font-semibold mb-4">Celebrating African Fashion</h3>
                                <div className="space-y-4">
                                    <div>
                                        <h4 className="font-medium text-gray-900">Ankara Prints</h4>
                                        <p className="text-gray-600">Vibrant wax-printed fabrics featuring bold patterns and colors, perfect for modern dresses and accessories.</p>
                                    </div>
                                    <div>
                                        <h4 className="font-medium text-gray-900">Traditional Kaftans</h4>
                                        <p className="text-gray-600">Elegant flowing garments adorned with intricate embroidery, suitable for both casual and formal occasions.</p>
                                    </div>
                                    <div>
                                        <h4 className="font-medium text-gray-900">Contemporary Fusion</h4>
                                        <p className="text-gray-600">Modern designs that blend traditional African textiles with contemporary fashion trends.</p>
                                    </div>
                                </div>
                            </div>

                            <Link 
                                to="/shop"
                                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-[#FEEA00] hover:bg-[#E6D300] transition duration-300"
                            >
                                Explore Our Products
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact CTA */}
            <div className="bg-gray-900 text-white py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-3xl font-bold mb-4">Get in Touch</h2>
                    <p className="text-gray-300 mb-8">
                        Have questions about our products or interested in partnering with us?
                    </p>
                    <Link 
                        to="/contact"
                        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-[#FEEA00] hover:bg-[#E6D300] transition duration-300"
                    >
                        Contact Us
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default About; 